type StepProps = {
	steps: {name:string, href:string, id:string}[]
	currentIndex?: number,
	onChange?: (value:number) => void
};

export default function Steps({
	steps,
	currentIndex = 0,
	onChange = undefined
}:StepProps) {

	const handleLinkClick = (e) => {
		e.preventDefault()
		if(onChange){
			onChange(+e.currentTarget.dataset.index)
		}
	}

	return (
		<nav aria-label="Progress">
			<ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
				{steps.map((step, index) => (
					<li key={step.name} className="md:flex-1">
						{index < currentIndex ? (
							<button
								onClick={handleLinkClick}
								data-index={index}
								className="flex flex-col items-start w-full py-2 pl-4 border-l-4 border-cobalt-500 cursor-pointer group hover:border-cobalt-400 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
							>
								<span className="text-xs font-semibold tracking-wide text-cobalt-500 uppercase group-hover:text-cobalt-400">
									{step.id}
								</span>
								<span className="text-sm font-medium">{step.name}</span>
							</button>
						) : index === currentIndex ? (
							<button
								onClick={handleLinkClick}
								data-index={index}
									className="flex flex-col items-start w-full py-2 pl-4 border-l-4 border-cobalt-500 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
								aria-current="step"
							>
								<span className="text-xs font-semibold tracking-wide text-cobalt-500 uppercase">{step.id}</span>
								<span className="text-sm font-medium">{step.name}</span>
							</button>
						) : (
							<button
								className="flex flex-col items-start w-full py-2 pl-4 border-l-4 border-gray-200 group md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
							>
								<span className="text-xs font-semibold tracking-wide text-gray-500 uppercase">
									{step.id}
								</span>
								<span className="text-sm font-medium">{step.name}</span>
							</button>
						)}
					</li>
				))}
			</ol>
		</nav>
	)
}