import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
	PlayIcon,
	PauseIcon,
	ViewListIcon,
	ArrowCircleLeftIcon,
	ArrowCircleRightIcon
} from '@heroicons/react/solid'
import WaveSurfer from 'wavesurfer.js';
import { Audio } from 'types/Audio';
import { Listbox, Transition } from '@headlessui/react';
import {getEnv} from "../../common/EnvUtil";
import LoaderSpinning from "./LoaderSpinning";

const formWaveSurferOptions = ref => ({
	container: ref,
	waveColor: "#999",
	progressColor: "#27348A",
	cursorColor: "#FF6F00",
	barWidth: 1,
	barRadius: 0,
	responsive: true,
	hideScrollbar: true,
	height: 50,
	barMinHeight: 1,
	// If true, normalize by the maximum peak instead of 1.0.
	normalize: true,
	// Use the PeakCache to improve rendering speed of large waveforms.
	partialRender: true,
	ignoreSilenceMode: true
});



const Waveform: React.FC <{audio:Audio[]}> = (props) => {
	const { REACT_APP_BASE_AUDIO_URL } = getEnv()
	const waveformRef = useRef(null);
	const wavesurfer = useRef <WaveSurfer| null>(null)

	const [firstTime, setFirstTime] = useState(true)
	const [playing, setPlay] = useState(false)
	const [showPlaylist, setShowPlaylist] = useState(false)
	const [volume] = useState(1);
	const [activeIndex, setActiveIndex] = useState<number>(0)
	const [activeAudio, setActiveAudio] = useState<Audio | null>()
	const [loading, setLoading] = useState<boolean>(true)

	useEffect( () => {
		if ( !localStorage.getItem( 'ftv-audioplay-firstime' ) ) {
			setShowPlaylist(true);
			localStorage.setItem('ftv-audioplay-firstime', '1')
		}

	}, [] )


	useEffect( () => {
		if ( props.audio[activeIndex] ) {
			setActiveAudio( props.audio[activeIndex] )
		}
	}, [activeIndex] )


	useEffect(() => {
		setActiveIndex( 0 )
		setActiveAudio( null )
		if (props.audio[activeIndex]) {
			setActiveAudio(props.audio[activeIndex])
		}

	},[props.audio])

	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent || '') ||
		/iPad|iPhone|iPod/i.test(navigator.userAgent || '');

	useEffect(() => {
		setPlay(false);

		if (activeAudio){

			const options = formWaveSurferOptions(waveformRef.current);
			wavesurfer.current = WaveSurfer.create(options);


			wavesurfer.current.load(REACT_APP_BASE_AUDIO_URL + activeAudio.path);
				wavesurfer.current.on("ready", () => {
					setLoading(false);
					if(!isSafari || !firstTime) {
						wavesurfer.current.play();
						setPlay(true);
						wavesurfer.current.setVolume(volume);
					}

				});

				wavesurfer.current.on("loading", () => {
					setLoading(true);
				})

				wavesurfer.current.on("finish", () => {
					setPlay(false);
				})
			return () => wavesurfer.current.destroy();
		}
	}, [activeAudio])// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!loading) wavesurfer.current.drawBuffer();
	}, [loading])

	const handlePlayPause = () => {
		setPlay(!playing);
		setFirstTime(false)
		wavesurfer.current.playPause();
	};

	const nextHandler = () =>{
		setActiveIndex((activeIndex + 1 ) % props.audio.length)
	}
	const previousHandler = () =>{
		setActiveIndex(((activeIndex - 1 ) + props.audio.length) % props.audio.length)
	}

	const onPlaylistChangeHandler = (e) => {
		setActiveIndex(e)
		setShowPlaylist(false)
	}


	return (
		<div className="flex items-center w-full space-x-4 ">
			<div className="flex items-center space-x-2">
				<button className="leading-none text-cobalt-500" onClick={handlePlayPause}>
					{!playing && <PlayIcon className="w-12 h-12 md:w-16 md:h-16"/>}
					{playing && <PauseIcon className="w-12 h-12 md:w-16 md:h-16"/>}
				</button>
				<button className="leading-none hover:text-blacks-600" onClick={previousHandler}>
					<ArrowCircleLeftIcon className="w-6 h-6" />
				</button>
				<button className="leading-none hover:text-blacks-600" onClick={nextHandler}>
					<ArrowCircleRightIcon className="w-6 h-6" />
				</button>

				<div className="relative leading-none">
				<Listbox value={activeIndex} onChange={onPlaylistChangeHandler} >

					{/* <Listbox.Button className="leading-none hover:text-blacks-600"><ViewListIcon className="w-6 h-6 leading-none"/></Listbox.Button> */}
						<button className="leading-none hover:text-blacks-600" onClick={(e)=>setShowPlaylist(!showPlaylist)}><ViewListIcon className="w-6 h-6 leading-none" /></button>
					<Transition
						show={showPlaylist}
						as={Fragment}
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
							<Listbox.Options className="absolute z-50 w-64 py-1 mt-1 overflow-auto bg-white rounded-md shadow-lg bottom-full max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
							{props.audio.map((audio, index) => (
								<Listbox.Option
									key={audio.id}
									value={index}
									className="relative py-2 pl-10 pr-4 text-gray-900 cursor-pointer select-none hover:bg-blacks-50 hover:text-cobalt-500"

								>
									{({ selected, active }) => (
										<>
											<span className={`${selected ? 'font-bold' : 'font-normal'} block truncate text-sm`}>
												{audio.title}
											</span>
											{selected ? (
												<span className={`${active ? 'text-cobalt-500' : 'text-cobalt-500'} absolute inset-y-0 left-0 flex items-center pl-3`}>
													<PlayIcon className="w-5 h-5" aria-hidden="true" />
												</span>
											) : null }
										</>

									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</Transition>
				</Listbox>
				</div>

			</div>
			<div className='w-full mt-6'>
				{loading && <LoaderSpinning className="" />}
				<div className={loading ? "hidden" : "w-full"} id="waveform" ref={waveformRef} />
				{!loading && activeAudio && <span className="text-xs italic"> {activeAudio.title}</span>}
			</div>
		</div>
	);
}

export default Waveform
