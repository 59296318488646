import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import SideBarNavigation from './SideBarNavigation'
import {
	XIcon,
} from '@heroicons/react/outline'


type props = {
	open: boolean;
	onClose: (open:boolean) => void;
}

export default function SideBar({open, onClose} : props) {

	return (
		<>
			<Transition.Root show={open} as={Fragment}>
				<Dialog
					as="div"
					static
					className="fixed inset-0 z-40 flex md:hidden"
					open={open}
					onClose={onClose}
				>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75" />
					</Transition.Child>
					<Transition.Child
						as={Fragment}
						enter="transition ease-in-out duration-300 transform"
						enterFrom="-translate-x-full"
						enterTo="translate-x-0"
						leave="transition ease-in-out duration-300 transform"
						leaveFrom="translate-x-0"
						leaveTo="-translate-x-full"
					>
						<div className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-cobalt-800">
							<Transition.Child
								as={Fragment}
								enter="ease-in-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in-out duration-300"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<div className="absolute top-0 right-0 pt-2 -mr-12">
									<button
										type="button"
										className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
										onClick={() => onClose(false)}
									>
										<span className="sr-only">Close sidebar</span>
										<XIcon className="w-6 h-6 text-white" aria-hidden="true" />
									</button>
								</div>
							</Transition.Child>
							<SideBarNavigation onClose={() => onClose(false)}/>
						</div>
					</Transition.Child>
					<div className="flex-shrink-0 w-14" aria-hidden="true">
						{/* Dummy element to force sidebar to shrink to fit close icon */}
					</div>
				</Dialog>
			</Transition.Root>

			{/* Static sidebar for desktop */}
			<div className="hidden bg-cobalt-800 md:flex md:flex-shrink-0">
				<div className="flex flex-col w-64">
					<SideBarNavigation />
				</div>
			</div>
		</>

	)

}