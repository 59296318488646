import React, { useState, useEffect } from 'react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline';
import LoaderSpinning from './LoaderSpinning';


export default function PaginatedSelectBox({
    hook,
    label,
    name,
    onChange,
    parse,
    value= { value: "", name: "" },
    pageSize= 30,
    optional = false
}) {
    const [selected, setSelected] = useState<any>({ value: "", name: "" })
    const [showOptions, setShowOptions] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState( 1 )
    const [totalItems, setTotalItems] = useState( 0 )
    const [items, setItems] = useState<any[]>([])
    const { data, isFetching: fetching } = hook({ itemsPerPage: pageSize, page: currentPage });

    useEffect(() => {
        setSelected(value)
    }, [value])

    useEffect(() => {
        if (!data) return
        if (data.totalItems == totalItems) {
            setItems(i => [...i, ...data.items.map(parse)])
        } else {
	        setItems(data.items.map(parse))
        }
	    setTotalItems(data.totalItems)
    }, [data])

    const onChangeItem = (item) => {
        onChange(item)
        setShowOptions(false)
    }

    const onScroll = (e) => {
        const hasReachedEnd = e.target.scrollHeight <= (e.target.scrollTop + e.target.offsetHeight)
        if (!hasReachedEnd) return
        if (currentPage * pageSize > totalItems) return
        setCurrentPage(currentPage + 1)
    }

    const toggleSelect = () => {
        setShowOptions(!showOptions)
    }

    return <div>
        {label && (
            <label
                htmlFor={name}
                className="flex justify-between items-end text-sm font-medium text-gray-700"
            >
                <span>{label}</span>
                {optional && <span className="text-xs text-gray-400">(optional)</span>}
            </label>
        )}
        <div onClick={toggleSelect}
             className={"bg-white border border-gray-300 block w-full min-h-[36px] my-1 px-3 py-2 rounded-md shadow-sm appearance-none sm:text-sm hover:cursor-pointer"}
        >
            {selected.value ? selected.name : "No " + name}
            <ChevronDownIcon className='float-right w-5 h-5 text-gray-400'/>
        </div>
        {showOptions && <div className="overflow-y-scroll max-h-64" onScroll={onScroll}>
            {optional && <div key={0} onClick={() => onChangeItem(null)}
                 className={(selected.value !== '' ? "group bg-white border border-gray-300 hover:bg-gray-100" : "bg-cobalt-500 text-white hover:bg-cobalt-300") + " block w-full min-h-[36px] my-1 px-3 py-2 transition-background duration-300 ease-in-out rounded-md shadow-sm appearance-none hover:cursor-pointer sm:text-sm"}
            >
            <i>No {name}</i>
            {selected.value !== '' && <CheckIcon className='float-right w-5 h-5 text-white group-hover:text-gray-400'/>}
            {selected.value === '' && <CheckIcon className='float-right w-5 h-5 text-gray'/>}
            </div> }
            {items && items.map(item =>
                <div key={item.value} onClick={() => onChangeItem(item)}
                     className={(selected.value !== item.value ? "group bg-white border border-gray-300 hover:bg-gray-100" : "bg-cobalt-500 text-white hover:bg-cobalt-300") + " block w-full min-h-[36px] my-1 px-3 py-2 transition-background duration-300 ease-in-out rounded-md shadow-sm appearance-none hover:cursor-pointer sm:text-sm"}
                >
                    {item.name}
                    {selected.value !== item.value && <CheckIcon className='float-right w-5 h-5 text-white group-hover:text-gray-400'/>}
                    {selected.value === item.value && <CheckIcon className='float-right w-5 h-5 text-gray'/>}
                </div>
            )}
            {fetching && <LoaderSpinning className="mt-2"></LoaderSpinning>}
        </div>}
    </div>
}
