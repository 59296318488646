import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/App.scss';
import './assets/css/tailwind.css';
import './i18n';
import App from './App';

import { QueryClientProvider } from 'react-query'
import { queryClient } from 'services/QueryClient'


ReactDOM.render(
  <React.StrictMode>
	<Router>
		<QueryClientProvider client={queryClient}>
			<App />
		</QueryClientProvider>
	</Router>
  </React.StrictMode>,
  document.getElementById('root')
);